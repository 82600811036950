.hx-ui-growl {
	position:fixed;
	top: 55px;
	right: 20px;
	width: 20em;
}

.hx-ui-growl-item-container {
	padding: 10px;
	position:relative;
	opacity:0.95;
	filter:alpha(opacity=95);
	border-radius: 4px;
}

.hx-ui-growl-item {
    position: relative;
	display: block;
	padding: .5em 1em;
}

.hx-ui-growl-item p {
	padding: 0;
	margin: 0;
}

.hx-ui-growl-icon-close {
	position: absolute;
	top: 4px;
	right: 4px;
	cursor: pointer;
}

.hx-ui-growl-title {
	font-weight: bold;
	padding: 0 0 .5em 0;
	display: block;
}

.hx-ui-growl-image {
    position: absolute;
    display: inline-block;
    left: .5em;
    top: .25em;
    padding: 0;
}

.hx-ui-growl-message {
	padding: 0 0 .25em 0;
    margin-left: 2.5em;
}

.hx-ui-growl-message p {
	font-weight: normal;
}

.hx-ui-growl-message-success {
		background-color:#2BCBA8;
}

.hx-ui-growl-message-error, .hx-ui-growl-message-warning {
		background-color:#EF7F5A;
}