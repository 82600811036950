@import "./color.scss";

.hx-ui-widget * {
    box-sizing: border-box;
}


.hx-ui-helper-clearfix:before,
.hx-ui-helper-clearfix:after {
    content: "";
    display: table;
}

.hx-ui-helper-clearfix:after {
    clear: both;
}

.hx-ui-helper-clearfix {
    zoom: 1;
}

.hx-ui-icon {
    display: block;
    text-indent: -99999px;
    overflow: hidden;
    background-repeat: no-repeat;
}

.hx-ui-widget-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 300%;
    background-color: black;
    opacity: 0.5;
}
.hx-ui-resizable {
    position: relative;
}
.hx-ui-resizable-handle {
    position: absolute;
    font-size: 0.1px;
    display: block;
}
.hx-ui-resizable-disabled .hx-ui-resizable-handle,
.hx-ui-resizable-autohide .hx-ui-resizable-handle {
    display: none;
}
.hx-ui-resizable-n {
    cursor: n-resize;
    height: 7px;
    width: 100%;
    top: -5px;
    left: 0;
}
.hx-ui-resizable-s {
    cursor: s-resize;
    height: 7px;
    width: 100%;
    bottom: -5px;
    left: 0;
}
.hx-ui-resizable-e {
    cursor: e-resize;
    width: 7px;
    right: -5px;
    top: 0;
    height: 100%;
}
.hx-ui-resizable-w {
    cursor: w-resize;
    width: 7px;
    left: -5px;
    top: 0;
    height: 100%;
}
.hx-ui-resizable-se {
    cursor: se-resize;
    width: 12px;
    height: 12px;
    right: 1px;
    bottom: 1px;
}
.hx-ui-resizable-sw {
    cursor: sw-resize;
    width: 9px;
    height: 9px;
    left: -5px;
    bottom: -5px;
}
.hx-ui-resizable-nw {
    cursor: nw-resize;
    width: 9px;
    height: 9px;
    left: -5px;
    top: -5px;
}
.hx-ui-resizable-ne {
    cursor: ne-resize;
    width: 9px;
    height: 9px;
    right: -5px;
    top: -5px;
}
.hx-ui-shadow {
    -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
}

.hx-ui-scrollbar-measure {
    width: 100px;
    height: 100px;
    overflow: scroll;
    position: absolute;
    top: -9999px;
}

.hx-ui-dialog {
    position: fixed;
    padding: 0;
    background-color: white;
    color: $elephant;
    border-radius: 4px;
}

.hx-ui-dialog .hx-ui-dialog-titlebar {
	padding: .5em .75em;
    position: relative;
    border: 0;
    background-color: $elephant;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: white;
}


.hx-ui-dialog .hx-ui-error-dialog-titlebar {
	padding: .5em .75em;
    position: relative;
    border: 0;
    background-color: red;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: white;
}

.hx-ui-dialog .hx-ui-dialog-content {
    position: relative;
    border: 0;
    padding: .5em .75em;
    background: none;
    overflow: auto;
    zoom: 1;
}

.hx-ui-dialog .hx-ui-dialog-buttonpane {
    text-align: left;
    border-width: 1px 0 0 0;
    background-image: none;
    margin: .5em 0 0 0;
    padding: .3em 1em .5em .4em;
}

.hx-ui-dialog .hx-ui-dialog-buttonpane .hx-ui-dialog-buttonset {
    float: right;
}

.hx-ui-dialog .hx-ui-dialog-buttonpane button {
    margin: .5em .4em .5em 0;
    cursor: pointer;
    float: right;
}

.hx-ui-dialog .hx-ui-resizable-se {
    width: 14px;
    height: 14px;
    right: 3px;
    bottom: 3px;
}

.hx-ui-dialog .hx-ui-dialog-titlebar-icon {
    text-decoration: none
}

.hx-ui-dialog .hx-ui-dialog-titlebar-close {
    float: right;
    padding: .125em;
    cursor: pointer;
    border: 1px solid transparent;
}

.hx-ui-dialog .hx-ui-dialog-titlebar-close span {
    display: block;
    margin: 0;
}

.hx-ui-dialog-footer {
    padding: .4em 1em;
    border-width: 1px 0 0 0;
    text-align: left;
}

.hx-ui-dialog-mask {
    position: fixed;
    width: 100%;
    height: 100%;
}

/* Confirm Dialog */
.hx-ui-confirmdialog.hx-ui-dialog .hx-ui-dialog-content {
    padding: 1em 2em;
}
.hx-ui-confirmdialog .hx-ui-dialog-content .fa {
    font-size: 1.5em;
    vertical-align: middle;
    margin-right: .5em;
}
.hx-ui-confirmdialog .hx-ui-dialog-content .hx-ui-confirmdialog-message {
    vertical-align: middle;
}
