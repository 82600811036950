
.running {
    margin: 10px;
    padding: 5px;
    border-radius: 4px;
    background-color: #444444;
    color: #2BCBA8;
}

.progress-bar { 
    color:#000!important;
    background-color:#555555!important
}
