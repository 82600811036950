@import "./color.scss";
@import "./button.scss";
@import "./font.scss";
.hx-form-search {
    position: relative; //color: #aaa;
    font-size: 16px;
}

.hx-form-warning {
    padding-top: 10px;
    font-size: 70%;
}

.hx-form-units {
    font-size: 70%;
}

.hx-form-row {
    margin-bottom: 15px;
}

.hx-form-error {
    color: red;
    font-size: 70%;
}

.hx-form-search input {
    font-size: 14px;
    width: 92%;
    padding: 0.5em 0.6em;
    border: 1px solid gray;
}

.hx-form-search-g {
    width: 100%;
}

.hx-form-search {
    margin: 2px;
    margin-right: 18px;
}

.hx-form input[type="text"],
.hx-form input[type="password"],
.hx-form input[type="email"],
.hx-form input[type="url"],
.hx-form input[type="date"],
.hx-form input[type="month"],
.hx-form input[type="time"],
.hx-form input[type="datetime"],
.hx-form input[type="datetime-local"],
.hx-form input[type="week"],
.hx-form input[type="number"],
.hx-form input[type="search"],
.hx-form input[type="tel"],
.hx-form input[type="color"],
.hx-form select,
.hx-form textarea {
    padding: 0.5em 0.6em;
    display: inline-block; //border: 1px solid #ccc;
    //box-shadow: inset 0 1px 3px #ddd;
    border-radius: 0;
    -webkit-appearance: none;
    font-size: 14px;
    vertical-align: middle;
    box-sizing: border-box;
    color: $elephant;
    font-family: $font-family-open-sans;
    height: 32px;
    background-color: white;
    margin: 2px;
    border: 1px solid gray;
}


/*
Need to separate out the :not() selector from the rest of the CSS 2.1 selectors
since IE8 won't execute CSS that contains a CSS3 selector.
*/

.hx-form input:not([type]) {
    padding: 0.5em 0.6em;
    display: inline-block;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 3px #ddd;
    box-sizing: border-box;
}


/* Chrome (as of v.32/34 on OS X) needs additional room for color to display. */


/* May be able to remove this tweak as color inputs become more standardized across browsers. */

.hx-form input[type="color"] {
    padding: 0.2em 0.5em;
}

.hx-form input[type="text"]:focus,
.hx-form input[type="password"]:focus,
.hx-form input[type="email"]:focus,
.hx-form input[type="url"]:focus,
.hx-form input[type="date"]:focus,
.hx-form input[type="month"]:focus,
.hx-form input[type="time"]:focus,
.hx-form input[type="datetime"]:focus,
.hx-form input[type="datetime-local"]:focus,
.hx-form input[type="week"]:focus,
.hx-form input[type="number"]:focus,
.hx-form input[type="search"]:focus,
.hx-form input[type="tel"]:focus,
.hx-form input[type="color"]:focus,
.hx-form select:focus,
.hx-form textarea:focus {
    outline: 0;
    border-color: #129FEA;
}


/*
Need to separate out the :not() selector from the rest of the CSS 2.1 selectors
since IE8 won't execute CSS that contains a CSS3 selector.
*/

.hx-form input:not([type]):focus {
    outline: 0;
    border-color: #129FEA;
}

.hx-form input[type="file"]:focus,
.hx-form input[type="radio"]:focus,
.hx-form input[type="checkbox"]:focus {
    outline: thin solid #129FEA;
    outline: 1px auto #129FEA;
}

.hx-form .hx-checkbox,
.hx-form .hx-radio {
    margin: 0.5em 0;
    display: block;
}

.hx-form input[type="text"][disabled],
.hx-form input[type="password"][disabled],
.hx-form input[type="email"][disabled],
.hx-form input[type="url"][disabled],
.hx-form input[type="date"][disabled],
.hx-form input[type="month"][disabled],
.hx-form input[type="time"][disabled],
.hx-form input[type="datetime"][disabled],
.hx-form input[type="datetime-local"][disabled],
.hx-form input[type="week"][disabled],
.hx-form input[type="number"][disabled],
.hx-form input[type="search"][disabled],
.hx-form input[type="tel"][disabled],
.hx-form input[type="color"][disabled],
.hx-form select[disabled],
.hx-form textarea[disabled] {
    cursor: not-allowed;
    background-color: #eaeded;
    color: #cad2d3;
}


/*
Need to separate out the :not() selector from the rest of the CSS 2.1 selectors
since IE8 won't execute CSS that contains a CSS3 selector.
*/

.hx-form input:not([type])[disabled] {
    cursor: not-allowed;
    background-color: #eaeded;
    color: #cad2d3;
}

.hx-form input[readonly],
.hx-form select[readonly],
.hx-form textarea[readonly] {
    background-color: #eee;
    /* menu hover bg color */
    color: #777;
    /* menu text color */
    border-color: #ccc;
}

.hx-form input:focus:invalid,
.hx-form textarea:focus:invalid,
.hx-form select:focus:invalid {
    color: #b94a48;
    border-color: #e9322d;
}

.hx-form input[type="file"]:focus:invalid:focus,
.hx-form input[type="radio"]:focus:invalid:focus,
.hx-form input[type="checkbox"]:focus:invalid:focus {
    outline-color: #e9322d;
}

.hx-form select[multiple] {
    height: auto;
}

.hx-form label {
    margin: 0.5em 0 0.2em;
    font-size: 80%;
}

.hx-form fieldset {
    margin: 0;
    padding: 0.35em 0 0.75em;
    border: 0;
}

.hx-form legend {
    display: block;
    width: 100%;
    padding: 0.3em 0;
    margin-bottom: 0.3em;
    color: #333;
    border-bottom: 1px solid #e5e5e5;
}

.hx-form li {
    list-style: none;
}

.hx-form-stacked input[type="text"],
.hx-form-stacked input[type="password"],
.hx-form-stacked input[type="email"],
.hx-form-stacked input[type="url"],
.hx-form-stacked input[type="date"],
.hx-form-stacked input[type="month"],
.hx-form-stacked input[type="time"],
.hx-form-stacked input[type="datetime"],
.hx-form-stacked input[type="datetime-local"],
.hx-form-stacked input[type="week"],
.hx-form-stacked input[type="number"],
.hx-form-stacked input[type="search"],
.hx-form-stacked input[type="tel"],
.hx-form-stacked input[type="color"],
.hx-form-stacked input[type="file"],
.hx-form-stacked select,
.hx-form-stacked label,
.hx-form-stacked textarea {
    display: block;
    margin: 0.25em 0;
}


/*
Need to separate out the :not() selector from the rest of the CSS 2.1 selectors
since IE8 won't execute CSS that contains a CSS3 selector.
*/

.hx-form-stacked input:not([type]) {
    display: block;
    margin: 0.25em 0;
}

.hx-form-aligned input,
.hx-form-aligned textarea,
.hx-form-aligned select,

/* NOTE: hx-help-inline is deprecated. Use .hx-form-message-inline instead. */

.hx-form-aligned .hx-help-inline,
.hx-form-message-inline {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    vertical-align: middle;
}

.hx-form-aligned textarea {
    vertical-align: top;
}


/* Aligned Forms */

.hx-form-aligned .hx-control-group {
    margin-bottom: 0.5em;
}

.hx-form-aligned .hx-control-group label {
    text-align: right;
    display: inline-block;
    vertical-align: middle;
    width: 15em;
    margin: 0 1em 0 0;
}

.hx-form-aligned .hx-controls {
    margin: 1.5em 0 0 11em;
}


/* Rounded Inputs */

.hx-form input.hx-input-rounded,
.hx-form .hx-input-rounded {
    border-radius: 2em;
    padding: 0.5em 1em;
}


/* Grouped Inputs */

.hx-form .hx-group fieldset {
    margin-bottom: 10px;
}

.hx-form .hx-group input,
.hx-form .hx-group textarea {
    display: block;
    padding: 10px;
    margin: 0 0 -1px;
    border-radius: 0;
    position: relative;
    top: -1px;
}

.hx-form .hx-group input:focus,
.hx-form .hx-group textarea:focus {
    z-index: 3;
}

.hx-form .hx-group input:first-child,
.hx-form .hx-group textarea:first-child {
    top: 1px;
    border-radius: 0 0 0 0;
    margin: 0;
}

.hx-form .hx-group input:first-child:last-child,
.hx-form .hx-group textarea:first-child:last-child {
    top: 1px; //border-radius: 4px;
    margin: 0;
}

.hx-form .hx-group input:last-child,
.hx-form .hx-group textarea:last-child {
    top: -2px;
    border-radius: 0 0 0 0;
    margin: 0;
}

.hx-form .hx-group button {
    margin: 0.35em 0;
}

.hx-form .hx-input-1 {
    width: 100%;
}

.hx-form .hx-input-3-4 {
    width: 75%;
}

.hx-form .hx-input-2-3 {
    width: 66%;
}

.hx-form .hx-input-1-2 {
    width: 50%;
}

.hx-form .hx-input-1-3 {
    width: 33%;
}

.hx-form .hx-input-1-4 {
    width: 25%;
}


/* Inline help for forms */


/* NOTE: hx-help-inline is deprecated. Use .hx-form-message-inline instead. */

.hx-form .hx-help-inline,
.hx-form-message-inline {
    display: inline-block;
    padding-left: 0.3em;
    color: #666;
    vertical-align: middle; //font-size: 0.875em;
}


/* Block help for forms */

.hx-form-message {
    display: block;
    color: #666; //font-size: 0.875em;
}

@media only screen and (max-width: 480px) {
    .hx-form button[type="submit"] {
        margin: 0.7em 0 0;
    }
    .hx-form input:not([type]),
    .hx-form input[type="text"],
    .hx-form input[type="password"],
    .hx-form input[type="email"],
    .hx-form input[type="url"],
    .hx-form input[type="date"],
    .hx-form input[type="month"],
    .hx-form input[type="time"],
    .hx-form input[type="datetime"],
    .hx-form input[type="datetime-local"],
    .hx-form input[type="week"],
    .hx-form input[type="number"],
    .hx-form input[type="search"],
    .hx-form input[type="tel"],
    .hx-form input[type="color"],
    .hx-form label {
        margin-bottom: 0.3em;
        display: block;
    }
    .hx-group input:not([type]),
    .hx-group input[type="text"],
    .hx-group input[type="password"],
    .hx-group input[type="email"],
    .hx-group input[type="url"],
    .hx-group input[type="date"],
    .hx-group input[type="month"],
    .hx-group input[type="time"],
    .hx-group input[type="datetime"],
    .hx-group input[type="datetime-local"],
    .hx-group input[type="week"],
    .hx-group input[type="number"],
    .hx-group input[type="search"],
    .hx-group input[type="tel"],
    .hx-group input[type="color"] {
        margin-bottom: 0;
    }
    .hx-form-aligned .hx-control-group label {
        margin-bottom: 0.3em;
        text-align: left;
        display: block;
        width: 100%;
    }
    .hx-form-aligned .hx-controls {
        margin: 1.5em 0 0 0;
    }
    /* NOTE: hx-help-inline is deprecated. Use .hx-form-message-inline instead. */
    .hx-form .hx-help-inline,
    .hx-form-message-inline,
    .hx-form-message {
        display: block;
        font-size: 0.75em;
        /* Increased bottom padding to make it group with its related input element. */
        padding: 0.2em 0 0.8em;
    }
}

.hx-form-panel {
    padding: 20px;
}

.hx-no-input {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid gray;
    
    background-color: white;
    font-size: 100%;
    color: $matisse;
    padding: 0.5em 0.6em;
}

.select2-selection--multiple {
    background-color: white;
    border: 1px solid #aaa;
    border-radius: 0px;
    cursor: text;
    outline: 0;
}

.select2-container .select2-choice {
    padding: 5px 10px;
    height: 40px;
    width: 132px;
    font-size: 1.2em;
}

.has-error input[type="text"],
.has-error input[type="password"],
.has-error input[type="email"],
.has-error input[type="url"],
.has-error input[type="date"],
.has-error input[type="month"],
.has-error input[type="time"],
.has-error input[type="datetime"],
.has-error input[type="datetime-local"],
.has-error input[type="week"],
.has-error input[type="number"],
.has-error input[type="search"],
.has-error input[type="tel"],
.has-error input[type="color"],
.has-error select,
.has-error textarea {
    border: 1px solid red;
}