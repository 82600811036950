@import "./font.scss";

/*csslint adjoining-classes: false, box-model:false*/

.hx-menu {
    box-sizing: border-box;
    background-color: #2b3347;
    font-family: $font-family-open-sans;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

.hx-menu-fixed {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 3;
}

.hx-menu-list,
.hx-menu-item {
    position: relative;
}

.hx-menu-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.hx-menu-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.hx-menu-item {
    padding: 0;
    margin: 0;
    height: 100%;
    text-transform: uppercase;
}

.hx-menu-link,
.hx-menu-heading {
    display: block;
    text-decoration: none;
    white-space: nowrap;
}

@media (min-width: 768px) {
    /* HORIZONTAL MENU */
    .hx-menu-horizontal {
        //width: 100%;
        white-space: nowrap;
    }
    .hx-menu-horizontal .hx-menu-list {
        display: inline-block;
    }
    /* Initial menus should be inline-block so that they are horizontal */
    .hx-menu-horizontal .hx-menu-item,
    .hx-menu-horizontal .hx-menu-heading,
    .hx-menu-horizontal .hx-menu-separator {
        display: inline-block;
        display: inline;
        zoom: 1;
        vertical-align: middle;
    }
}


/* misc default styling */

.hx-menu-separator,
.hx-menu-horizontal .hx-menu-children .hx-menu-separator {
    background-color: #ccc;
    height: 1px;
    margin: .3em 0;
}

.hx-logo {
    padding-left: 20px;
    padding-right: 20px;
}

img {
    display: block-inline;
    height: auto;
    vertical-align: middle;
    border: 0px;
}

.hx-right {
    float: right;
    display: block;
    width: auto;
}

.hx-menu-horizontal .hx-menu-separator {
    width: 1px;
    height: 1.3em;
    margin: 0 .3em;
}


/* Need to reset the separator since submenu is vertical */

.hx-menu-horizontal .hx-menu-children .hx-menu-separator {
    display: block; //width: auto;
}

.hx-menu-heading {
    text-transform: uppercase;
    color: white;
}

.hx-right-padding-10 {
    padding-right: 10px;
}

.hx-menu-link {
    color: white;
}

.hx-menu-children {
    background-color: #2b3347;
}

.hx-menu-link,
.hx-menu-disabled,
.hx-menu-heading {
    padding-bottom: 16px;
    padding-top: 16px;
}

.hx-menu-disabled {
    opacity: .5;
}

.hx-menu-disabled .hx-menu-link:hover {
    background-color: transparent;
}

.hx-menu-active>.hx-menu-link,
.hx-menu-link:hover {
    padding-bottom: 11px;
    padding-top: 16px;
    border-bottom: 5px solid #0E70A8;
}

.hx-menu-selected .hx-menu-link,
.hx-menu-selected .hx-menu-link:visited {
    color: #000;
}