/* You can add global styles to this file, and also import other style files */

@import "assets/stylesheets/app";
@import "assets/stylesheets/core/reset";
@import "assets/stylesheets/core/color";
@import "assets/stylesheets/core/button";
@import "assets/stylesheets/core/variable";
@import "assets/stylesheets/core/content";
@import "assets/stylesheets/core/menu";
@import "assets/stylesheets/core/table";
@import "assets/stylesheets/core/form";
@import "assets/stylesheets/core/section";
@import "assets/stylesheets/core/dialog";
@import "assets/stylesheets/core/growl";
@import "assets/stylesheets/core/metric";
@import "assets/stylesheets/core/grid-base";
@import "assets/stylesheets/core/grid";
@import "assets/stylesheets/core/icon";
@import "assets/stylesheets/core/alert";
@import "assets/stylesheets/core/status";

@import url('https://fonts.googleapis.com/css?family=Roboto:100|Open+Sans:100,200,300,400,300,600,700,800');

  @-webkit-keyframes spin {
    0% {
      transform: rotate(0deg);
      /* Firefox 16+, IE 10+, Opera */
    }
    100% {
      transform: rotate(360deg);
      /* Firefox 16+, IE 10+, Opera */
    }
  }
  
  @-moz-keyframes spin {
    0% {
      -moz-transform: rotate(0deg);
      /* Firefox 16+*/
    }
    100% {
      -moz-transform: rotate(360deg);
      /* Firefox 16+*/
    }
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
      /* Firefox 16+, IE 10+, Opera */
    }
    100% {
      transform: rotate(360deg);
      /* Firefox 16+, IE 10+, Opera */
    }
  }
  
  #preloader {
    display:none; // By default it is not displayed
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1003;
    background: black;
    opacity: 0;
  }
  
  #preloader > div {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: white;
    transform: translate3d(0, 0, 0);
    animation: spin 2s linear infinite;
    /* Chrome, Firefox 16+, IE 10+, Opera */
  }
  
  #preloader > div:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: white;
    -webkit-animation: spin 3s linear infinite;
    /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 3s linear infinite;
    /* Chrome, Firefox 16+, IE 10+, Opera */
  }
  
  #preloader > div:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: white;
    animation: spin 1.5s linear infinite;
    /* Chrome, Firefox 16+, IE 10+, Opera */
  }



  #spinner {
    width: 100%;
    height: 400px;
    z-index: 1003;
    background: black;
    opacity: 0.3;
  }
  
  #spinner > div {
    display: block;
    position: relative;
    left: 437px;
    top: 120px;
    width: 150px;
    height: 150px;
    //margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: white;
    transform: translate3d(0, 0, 0);
    animation: spin 2s linear infinite;
    /* Chrome, Firefox 16+, IE 10+, Opera */
  }
  
  #spinner > div:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: white;
    -webkit-animation: spin 3s linear infinite;
    /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 3s linear infinite;
    /* Chrome, Firefox 16+, IE 10+, Opera */
  }
  
  #spinner > div:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: white;
    animation: spin 1.5s linear infinite;
    /* Chrome, Firefox 16+, IE 10+, Opera */
  }
  
.mydrp {
    border: 0px;
}

