//svg text {
//   font-family: 'hexoskin-icons';
//   font-size: 18px;
//}
@font-face {
    font-family: 'hexoskin-icons';
    src: url('/assets/fonts/hexoskin-icons.eot');
    src: url('/assets/fonts/hexoskin-icons.eot#iefix') format('embedded-opentype'), url('/assets/fonts/hexoskin-icons.ttf') format('truetype'), url('/assets/fonts/hexoskin-icons.woff') format('woff'), url('/assets/fonts/hexoskin-icons.svg#hexoskin-icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src:  url('/assets/fonts/icomoon.eot?1g59hh');
  src:  url('/assets/fonts/icomoon.eot?1g59hh#iefix') format('embedded-opentype'),
    url('/assets/fonts/icomoon.ttf?1g59hh') format('truetype'),
    url('/assets/fonts/icomoon.woff?1g59hh') format('woff'),
    url('/assets/fonts/icomoon.svg?1g59hh#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.hx-icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'hexoskin-icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 18px; //color: #FA5B6B;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.hx-icon-lightened {
    color: #BBB;
}

.hx-icon-inactive {
    color: #BBB;
}

.hx-small-icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'hexoskin-icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 10px; //color: #FA5B6B;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.hx-tiny-icon {
    font-size: 12px;
    color: gray;
}

.hx-icon-success {
    background-color: green;
}

.hx-icon-success-color {
    color: #2BCBA8;
}

.hx-icon-warning-color {
    color: #EF7F5A;
}

.hx-icon-white {
    color: white;
}

.hx-icon-calendar:before {
    content: "\e902";
}

.hx-icon-heart-rate:before {
    content: "\e600";
}

.hx-icon-energy:before {
    content: "\e601";
}

.hx-icon-steps:before {
    content: "\e602";
}

.hx-icon-distance:before {
    content: "\e603";
}

.hx-icon-heart-rate-variability:before {
    content: "\e604";
}

.hx-icon-heart-rate-recovery:before {
    content: "\e605";
}

.hx-icon-breathing-rate:before {
    content: "\e606";
}

.hx-icon-minute-ventilation:before {
    content: "\e607";
}

.hx-icon-vo2-max:before {
    content: "\e608";
}

.hx-icon-breathing-volume:before {
    content: "\e609";
}

.hx-icon-cadence:before {
    content: "\e60a";
}

.hx-icon-spo2:before {
    content: "\e60b";
}

.hx-icon-sleep-time:before {
    content: "\e60c";
}

.hx-icon-sleep-position-1:before {
    content: "\e60d";
}

.hx-icon-sleep-position-2:before {
    content: "\e60e";
}

.hx-icon-sleep-position-3:before {
    content: "\e60f";
}

.hx-icon-sleep-position-4:before {
    content: "\e610";
}

.hx-icon-sleep-position-5:before {
    content: "\e611";
}

.hx-icon-sleep-positions:before {
    content: "\e612";
}

.hx-icon-sleep:before {
    content: "\e613";
}

.hx-icon-skin-temperature:before {
    content: "\e614";
}

.hx-icon-hour-glass:before {
    content: "\e65a";
}

.hx-icon-clock:before {
    content: "\e63f";
}

.hx-icon-alarm-clock:before {
    content: "\e65c";
}

.hx-icon-pace:before {
    content: "\e615";
}

.hx-icon-map:before {
    content: "\e616";
}

.hx-icon-speed:before {
    content: "\e617";
}

.hx-icon-average:before {
    content: "\e618";
}

.hx-icon-blood-pressure:before {
    content: "\e619";
}

.hx-icon-activity-level:before {
    content: "\e61a";
}

.hx-icon-time-active:before {
    content: "\e658";
}

.hx-icon-acceleration:before {
    content: "\e61b";
}

.hx-icon-bell-ring:before {
    content: "\e61c";
}

.hx-icon-bell-o:before {
    content: "\e61d";
}

.hx-icon-caret-down:before {
    content: "\e61f";
}

.hx-icon-caret-up:before {
    content: "\e699";
}

.hx-icon-caret-left:before {
    content: "\e69a";
}

.hx-icon-caret-right:before {
    content: "\e61e";
}

.hx-icon-chevron-down:before {
    content: "\e620";
}

.hx-icon-chevron-up:before {
    content: "\e621";
}

.hx-icon-chevron-left:before {
    content: "\e622";
}

.hx-icon-chevron-right:before {
    content: "\e698";
}

.hx-icon-bluetooth:before {
    content: "\e623";
}

.hx-icon-check:before {
    content: "\e625";
}

.hx-icon-location-arrow:before {
    content: "\e626";
}

.hx-icon-at:before {
    content: "\e627";
}

.hx-icon-home:before {
    content: "\e628";
}

.hx-icon-info-circle:before {
    content: "\e629";
}

.hx-icon-info-circle-o:before {
    content: "\e62a";
}

.hx-icon-refresh:before {
    content: "\e62b";
}

.hx-icon-location:before {
    content: "\e62c";
}

.hx-icon-log-out:before {
    content: "\e62d";
}

.hx-icon-notification-new:before {
    content: "\e62e";
}

.hx-icon-envelope-o:before {
    content: "\e62f";
}

.hx-icon-bars:before {
    content: "\e630";
}

.hx-icon-bars-v:before {
    content: "\e65f";
}

.hx-icon-ellipsis-v:before {
    content: "\e660";
}

.hx-icon-plus:before {
    content: "\e631";
}

.hx-icon-minus:before {
    content: "\e64b";
}

.hx-icon-flag-start:before {
    content: "\e632";
}

.hx-icon-flag-finish:before {
    content: "\e633";
}

.hx-icon-flag:before {
    content: "\e634";
}

.hx-icon-crosshairs:before {
    content: "\e635";
}

.hx-icon-warning:before {
    content: "\e636";
}

.hx-icon-web:before {
    content: "\e637";
}

.hx-icon-times:before {
    content: "\e638";
}

.hx-icon-eye:before {
    content: "\e639";
}

.hx-icon-edit:before {
    content: "\e63c";
}

.hx-icon-delete:before {
    content: "\e63e";
}

.hx-icon-save:before {
    content: "\e63a";
}

.hx-icon-paper-clip:before {
    content: "\e901";
}

.hx-icon-search:before {
    content: "\e63b";
}

.hx-icon-download:before {
    content: "\e63d";
}

.hx-icon-zombie:before {
    content: "\e640";
}

.hx-icon-yoga:before {
    content: "\e641";
}

.hx-icon-work:before {
    content: "\e642";
}

.hx-icon-warm-up:before {
    content: "\e643";
}

.hx-icon-weight-lifting:before {
    content: "\e644";
}

.hx-icon-walk:before {
    content: "\e645";
}

.hx-icon-volleyball:before {
    content: "\e646";
}

.hx-icon-tennis:before {
    content: "\e647";
}

.hx-icon-stretch:before {
    content: "\e648";
}

.hx-icon-soccer:before {
    content: "\e649";
}

.hx-icon-snowboard:before {
    content: "\e64a";
}

.hx-icon-downhill-skiing:before {
    content: "\e64c";
}

.hx-icon-skating:before {
    content: "\e64d";
}

.hx-icon-running:before {
    content: "\e64e";
}

.hx-icon-swimming:before {
    content: "\e900";
}

.hx-icon-rugby:before {
    content: "\e64f";
}

.hx-icon-rowing:before {
    content: "\e650";
}

.hx-icon-roller-skating:before {
    content: "\e651";
}

.hx-icon-rest:before {
    content: "\e652";
}

.hx-icon-other:before {
    content: "\e653";
}

.hx-icon-mountain-biking:before {
    content: "\e654";
}

.hx-icon-meditating:before {
    content: "\e655";
}

.hx-icon-manual-entry:before {
    content: "\e683";
}

.hx-icon-karting:before {
    content: "\e684";
}

.hx-icon-hockey:before {
    content: "\e685";
}

.hx-icon-hiking:before {
    content: "\e686";
}

.hx-icon-golf:before {
    content: "\e687";
}

.hx-icon-frisbee:before {
    content: "\e688";
}

.hx-icon-football:before {
    content: "\e689";
}

.hx-icon-crossfit:before {
    content: "\e68a";
}

.hx-icon-dancing:before {
    content: "\e68b";
}

.hx-icon-cross-country-skiing:before {
    content: "\e68c";
}

.hx-icon-cricket:before {
    content: "\e68d";
}

.hx-icon-cool-down:before {
    content: "\e68e";
}

.hx-icon-cardio-training:before {
    content: "\e68f";
}

.hx-icon-calisthenics:before {
    content: "\e690";
}

.hx-icon-boxing:before {
    content: "\e691";
}

.hx-icon-cycling:before {
    content: "\e692";
}

.hx-icon-basketball:before {
    content: "\e693";
}

.hx-icon-baseball:before {
    content: "\e694";
}

.hx-icon-badminton:before {
    content: "\e695";
}

.hx-icon-australian-football:before {
    content: "\e696";
}

.hx-icon-martial-arts:before {
    content: "\e656";
}

.hx-icon-5-minute-rest-test:before {
    content: "\e697";
}

.hx-icon-sleep-efficiency:before {
    content: "\e657";
}

.hx-icon-sleep-position-changes:before {
    content: "\e659";
}

.hx-icon-sheep:before {
    content: "\e65b";
}

.hx-icon-time-in-bed:before {
    content: "\e65d";
}

.hx-icon-bed:before {
    content: "\e65e";
}

// Icon as a button
.hx-icon-button {
    cursor: pointer;
}

.hx-icon-button:hover {
    color: #122F4C;
}