$content-max-width: 1280px;
$footer-height: 60px;

.hx-content {
    max-width: $content-max-width;
    padding-top: 60px;
    /* Negative indent footer by it's height */   
    margin: 0 auto (-$footer-height);
}

.hx-page-top {
    background-color: #fff;
    position: fixed;
    z-index: 904;
    box-shadow: -2px 0 -3px rgba(0, 0, 0, 0.5);
    width: 100%;
    min-width: 320px;
}



.hx-panel {
    background-color: rgba(255, 255, 255, 0.1);
    .hx-menu-list {
        background-color: white;
    }
    .hx-tab-content {
        background-color: white;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        border-left: 1px solid black;
        border-right: 1px solid black;
    }
    .hx-tab-menu {
        background-color: #FFF;
        padding: 0 8px 0 8px;
    }
    .hx-tab-menu .hx-tab-item {
        display: inline-block;
        *display: inline;
        zoom: 1;
        vertical-align: middle;

        button {
            border-radius: 0;
            height: 32px;
            border: 1px solid #122F4C;
            background-color: white;
            color: #122F4C;
            padding: 4px 8px 4px 8px;
            display: inline-block;
            zoom: 1;
            line-height: normal;
            white-space: nowrap;
            vertical-align: middle;
            text-align: center;
            cursor: pointer;
            -webkit-user-drag: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            box-sizing: border-box;
            font-family: inherit;
            font-size: 100%;        
            text-decoration: none;
            height: 32px;
            margin: 4px;
        }
        button:hover {
            background-color: #122F4C;
            color: white;
            border: 1px solid #122F4C;        
        }
        .active {
            background-color: #122F4C;
            color: white;
            border: 1px solid #122F4C;
        }
    }
}