// grid with avg, max, min
@import "../app";
@import "./variable";
@import "./color.scss";
.hx-pane {
    color: $elephant;
    background-color: white;
    opacity: 0.9;
    display: flex;
    align-items: center;
}

.left {
    min-height: 80px;
    vertical-align: middle;
    .hx-icon {
        color: $color-zone-excellent
    }
}

.maxright {
    min-height: 80px;
    vertical-align: middle;
    margin-left: 1px;
    .hx-icon {
        color: $color-zone-poor
    }
}

.minright {
    min-height: 80px;
    vertical-align: middle;
    margin-left: 1px;
    .hx-icon {
        color: $color-zone-good
    }
}

.top {
    min-height: 40px;
    vertical-align: middle;
    margin-left: 1px;
    .hx-icon {
        color: $color-zone-poor
    }
}

.bottom {
    min-height: 39px;
    vertical-align: middle;
    margin-left: 1px;
    .hx-icon {
        color: $color-zone-good
    }
}

.first_line {
    .hx-metric-box-heading {
        margin: 0px 1px 1px 0;
    }
}

.th_line {
    .hx-metric-box-heading {
        margin: 1px 1px 1px 0;
    }
}

.right {
    .hx-metric-box-heading {
        margin-right: 0;
    }
    .metric-box-body {
        margin-right: 0px;
    }
}

.hx-metric-box-heading {
    background-color: white;
    padding: 0 5px 0 10px;
    color: black;
    margin-right: 1px;
    /*.right {
        margin: 1px 0px 1px 0;
    }*/
    .manual {
        background-color: #122F4C;
        color: white;
    }
}



.metric-box-title {
    font-size: $font-size-base;
    font-weight: $font-weight-normal;
    line-height: 30px;
    margin: 0;
    @include letter-spacing(25);
    color: $elephant;
    .hx-icon {
        font-size: inherit;
        margin-right: 5px;
    }
    >small {
        color: inherit;
        font-size: floor(($font-size-base * 0.75)); // 10px
        @include letter-spacing(25);
    }
}

@media (max-width: 1024px) {
    .metric-box-title {
        font-size: 80%;
    }
}

$metric-box-body-height: 80px;
.metric-box-value {
    //font-family: $font-family-helvetica-neue;
    font-weight: $font-weight-light;
    text-align: center;
    @include letter-spacing(-50);
}

.metric-box-body-selected {
    background-color: white;
} // box with only one count
.metric-box-range {
    @media (max-width: $screen-xs-max) {
        &.odd {
            border-left: 0;
        }
    }
    &.is-active .metric-box-body {
        @include opacity(1);
    }
    .metric-box-body {
        // background-color: red;
        min-height: 92px;
        background-color: rgba(255, 255, 255, 0.9); //    @include opacity(0.9);
        &:hover {
            @include opacity(1);
        }
    }
    .metric-box-value {
        font-size: 24px;
        width: 100%;
        padding: 10px 0 10px;
    }
} // box with only one count
.metric-box-count {
    @media (max-width: $screen-xs-max) {
        &.odd {
            border-left: 0;
        }
    }
    &.is-active .metric-box-body {
        @include opacity(1);
    }
    .metric-box-body {
        background-color: $white;
        @include opacity(0.9);
        &:hover {
            @include opacity(1);
        }
    }
    .metric-box-value {
        font-size: 30px;
        line-height: $metric-box-body-height;
        width: 100%;
    }
}

.metric-box-body {
    // maximum value
    .hx-icon-caret-up {
        //  color: white;
        //opacity: 0.3;
    } // minimum value
    .hx-icon-caret-down {
        //  color: white;
        //opacity: 0.3;
    } // average value
    .hx-icon-average {
        //  color: white;
        //opacity: 0.3;
    }
    margin-right: 1px;
} // grid with avg, max, min
.metric-box-grid {
    .metric-box-value {
        font-size: 26px;
    }
    .metric-box-body {
        .left {
            display: flex;
            align-items: center;
            min-height: 80px;
        }
        .pane {
            background-color: rgba(255, 255, 255, 0.9); //      background-color: #fff;
            //      @include opacity(0.9);
        }
        .top,
        .bottom,
        .left {
            text-align: center;
        }
        .top {
            margin-bottom: 1px;
        }
        .left .metric-box-value {
            line-height: 80px;
        }
        .top,
        .bottom {
            //font-size: 26px;
            line-height: 1;
        }
        .top>.metric-box-value,
        .bottom>.metric-box-value {
            display: inline-block;
            float: right;
            text-align: left;
            width: 65%;
        }
        .top>.metric-box-value {
            line-height: 40px;
        }
        .bottom>.metric-box-value {
            line-height: 39px;
        }
        .top .hx-icon {
            line-height: 40px;
        }
        .bottom .hx-icon {
            line-height: 39px;
        }
        .top .hx-icon,
        .bottom .hx-icon {
            width: 35%;
            display: inline-block;
            font-size: 14px;
            vertical-align: middle;
        }
        .left .hx-icon {
            font-size: 20px;
            margin-bottom: 4px;
        }
    }
}