@media screen and (min-width:400px) {
    .hx-u-xs-1,
    .hx-u-xs-1-1,
    .hx-u-xs-1-12,
    .hx-u-xs-1-2,
    .hx-u-xs-1-24,
    .hx-u-xs-1-3,
    .hx-u-xs-1-4,
    .hx-u-xs-1-5,
    .hx-u-xs-1-6,
    .hx-u-xs-1-8,
    .hx-u-xs-10-24,
    .hx-u-xs-11-12,
    .hx-u-xs-11-24,
    .hx-u-xs-12-24,
    .hx-u-xs-13-24,
    .hx-u-xs-14-24,
    .hx-u-xs-15-24,
    .hx-u-xs-16-24,
    .hx-u-xs-17-24,
    .hx-u-xs-18-24,
    .hx-u-xs-19-24,
    .hx-u-xs-2-24,
    .hx-u-xs-2-3,
    .hx-u-xs-2-5,
    .hx-u-xs-20-24,
    .hx-u-xs-21-24,
    .hx-u-xs-22-24,
    .hx-u-xs-23-24,
    .hx-u-xs-24-24,
    .hx-u-xs-3-24,
    .hx-u-xs-3-4,
    .hx-u-xs-3-5,
    .hx-u-xs-3-8,
    .hx-u-xs-4-24,
    .hx-u-xs-4-5,
    .hx-u-xs-5-12,
    .hx-u-xs-5-24,
    .hx-u-xs-5-5,
    .hx-u-xs-5-6,
    .hx-u-xs-5-8,
    .hx-u-xs-6-24,
    .hx-u-xs-7-12,
    .hx-u-xs-7-24,
    .hx-u-xs-7-8,
    .hx-u-xs-8-24,
    .hx-u-xs-9-24 {
        display: inline-block;
        zoom: 1;
        letter-spacing: normal;
        word-spacing: normal;
        //vertical-align: top;
        text-rendering: auto
    }
    .hx-u-xs-1-24 {
        width: 4.1667%
    }
    .hx-u-xs-1-12,
    .hx-u-xs-2-24 {
        width: 8.3333%
    }
    .hx-u-xs-1-8,
    .hx-u-xs-3-24 {
        width: 12.5%
    }
    .hx-u-xs-1-6,
    .hx-u-xs-4-24 {
        width: 16.6667%
    }
    .hx-u-xs-1-5 {
        width: 20%
    }
    .hx-u-xs-5-24 {
        width: 20.8333%
    }
    .hx-u-xs-1-4,
    .hx-u-xs-6-24 {
        width: 25%
    }
    .hx-u-xs-7-24 {
        width: 29.1667%
    }
    .hx-u-xs-1-3,
    .hx-u-xs-8-24 {
        width: 33.3333%
    }
    .hx-u-xs-3-8,
    .hx-u-xs-9-24 {
        width: 37.5%
    }
    .hx-u-xs-2-5 {
        width: 40%
    }
    .hx-u-xs-10-24,
    .hx-u-xs-5-12 {
        width: 41.6667%
    }
    .hx-u-xs-11-24 {
        width: 45.8333%
    }
    .hx-u-xs-1-2,
    .hx-u-xs-12-24 {
        width: 50%
    }
    .hx-u-xs-13-24 {
        width: 54.1667%
    }
    .hx-u-xs-14-24,
    .hx-u-xs-7-12 {
        width: 58.3333%
    }
    .hx-u-xs-3-5 {
        width: 60%
    }
    .hx-u-xs-15-24,
    .hx-u-xs-5-8 {
        width: 62.5%
    }
    .hx-u-xs-16-24,
    .hx-u-xs-2-3 {
        width: 66.6667%
    }
    .hx-u-xs-17-24 {
        width: 70.8333%
    }
    .hx-u-xs-18-24,
    .hx-u-xs-3-4 {
        width: 75%
    }
    .hx-u-xs-19-24 {
        width: 79.1667%
    }
    .hx-u-xs-4-5 {
        width: 80%
    }
    .hx-u-xs-20-24,
    .hx-u-xs-5-6 {
        width: 83.3333%
    }
    .hx-u-xs-21-24,
    .hx-u-xs-7-8 {
        width: 87.5%
    }
    .hx-u-xs-11-12,
    .hx-u-xs-22-24 {
        width: 91.6667%
    }
    .hx-u-xs-23-24 {
        width: 95.8333%
    }
    .hx-u-xs-1,
    .hx-u-xs-1-1,
    .hx-u-xs-24-24,
    .hx-u-xs-5-5 {
        width: 100%
    }
}

@media screen and (min-width:35.5em) {
    .hx-u-sm-1,
    .hx-u-sm-1-1,
    .hx-u-sm-1-12,
    .hx-u-sm-1-2,
    .hx-u-sm-1-24,
    .hx-u-sm-1-3,
    .hx-u-sm-1-4,
    .hx-u-sm-1-5,
    .hx-u-sm-1-6,
    .hx-u-sm-1-8,
    .hx-u-sm-10-24,
    .hx-u-sm-11-12,
    .hx-u-sm-11-24,
    .hx-u-sm-12-24,
    .hx-u-sm-13-24,
    .hx-u-sm-14-24,
    .hx-u-sm-15-24,
    .hx-u-sm-16-24,
    .hx-u-sm-17-24,
    .hx-u-sm-18-24,
    .hx-u-sm-19-24,
    .hx-u-sm-2-24,
    .hx-u-sm-2-3,
    .hx-u-sm-2-5,
    .hx-u-sm-20-24,
    .hx-u-sm-21-24,
    .hx-u-sm-22-24,
    .hx-u-sm-23-24,
    .hx-u-sm-24-24,
    .hx-u-sm-3-24,
    .hx-u-sm-3-4,
    .hx-u-sm-3-5,
    .hx-u-sm-3-8,
    .hx-u-sm-4-24,
    .hx-u-sm-4-5,
    .hx-u-sm-5-12,
    .hx-u-sm-5-24,
    .hx-u-sm-5-5,
    .hx-u-sm-5-6,
    .hx-u-sm-5-8,
    .hx-u-sm-6-24,
    .hx-u-sm-7-12,
    .hx-u-sm-7-24,
    .hx-u-sm-7-8,
    .hx-u-sm-8-24,
    .hx-u-sm-9-24 {
        display: inline-block;
        zoom: 1;
        letter-spacing: normal;
        word-spacing: normal;
        //vertical-align: top;
        text-rendering: auto
    }
    .hx-u-sm-1-24 {
        width: 4.1667%
    }
    .hx-u-sm-1-12,
    .hx-u-sm-2-24 {
        width: 8.3333%
    }
    .hx-u-sm-1-8,
    .hx-u-sm-3-24 {
        width: 12.5%
    }
    .hx-u-sm-1-6,
    .hx-u-sm-4-24 {
        width: 16.6667%
    }
    .hx-u-sm-1-5 {
        width: 20%
    }
    .hx-u-sm-5-24 {
        width: 20.8333%
    }
    .hx-u-sm-1-4,
    .hx-u-sm-6-24 {
        width: 25%
    }
    .hx-u-sm-7-24 {
        width: 29.1667%
    }
    .hx-u-sm-1-3,
    .hx-u-sm-8-24 {
        width: 33.3333%
    }
    .hx-u-sm-3-8,
    .hx-u-sm-9-24 {
        width: 37.5%
    }
    .hx-u-sm-2-5 {
        width: 40%
    }
    .hx-u-sm-10-24,
    .hx-u-sm-5-12 {
        width: 41.6667%
    }
    .hx-u-sm-11-24 {
        width: 45.8333%
    }
    .hx-u-sm-1-2,
    .hx-u-sm-12-24 {
        width: 50%
    }
    .hx-u-sm-13-24 {
        width: 54.1667%
    }
    .hx-u-sm-14-24,
    .hx-u-sm-7-12 {
        width: 58.3333%
    }
    .hx-u-sm-3-5 {
        width: 60%
    }
    .hx-u-sm-15-24,
    .hx-u-sm-5-8 {
        width: 62.5%
    }
    .hx-u-sm-16-24,
    .hx-u-sm-2-3 {
        width: 66.6667%
    }
    .hx-u-sm-17-24 {
        width: 70.8333%
    }
    .hx-u-sm-18-24,
    .hx-u-sm-3-4 {
        width: 75%
    }
    .hx-u-sm-19-24 {
        width: 79.1667%
    }
    .hx-u-sm-4-5 {
        width: 80%
    }
    .hx-u-sm-20-24,
    .hx-u-sm-5-6 {
        width: 83.3333%
    }
    .hx-u-sm-21-24,
    .hx-u-sm-7-8 {
        width: 87.5%
    }
    .hx-u-sm-11-12,
    .hx-u-sm-22-24 {
        width: 91.6667%
    }
    .hx-u-sm-23-24 {
        width: 95.8333%
    }
    .hx-u-sm-1,
    .hx-u-sm-1-1,
    .hx-u-sm-24-24,
    .hx-u-sm-5-5 {
        width: 100%
    }
}

@media screen and (min-width:48em) {
    .hx-u-md-1,
    .hx-u-md-1-1,
    .hx-u-md-1-12,
    .hx-u-md-1-2,
    .hx-u-md-1-24,
    .hx-u-md-1-3,
    .hx-u-md-1-4,
    .hx-u-md-1-5,
    .hx-u-md-1-6,
    .hx-u-md-1-8,
    .hx-u-md-10-24,
    .hx-u-md-11-12,
    .hx-u-md-11-24,
    .hx-u-md-12-24,
    .hx-u-md-13-24,
    .hx-u-md-14-24,
    .hx-u-md-15-24,
    .hx-u-md-16-24,
    .hx-u-md-17-24,
    .hx-u-md-18-24,
    .hx-u-md-19-24,
    .hx-u-md-2-24,
    .hx-u-md-2-3,
    .hx-u-md-2-5,
    .hx-u-md-20-24,
    .hx-u-md-21-24,
    .hx-u-md-22-24,
    .hx-u-md-23-24,
    .hx-u-md-24-24,
    .hx-u-md-3-24,
    .hx-u-md-3-4,
    .hx-u-md-3-5,
    .hx-u-md-3-8,
    .hx-u-md-4-24,
    .hx-u-md-4-5,
    .hx-u-md-5-12,
    .hx-u-md-5-24,
    .hx-u-md-5-5,
    .hx-u-md-5-6,
    .hx-u-md-5-8,
    .hx-u-md-6-24,
    .hx-u-md-7-12,
    .hx-u-md-7-24,
    .hx-u-md-7-8,
    .hx-u-md-8-24,
    .hx-u-md-9-24 {
        display: inline-block;
        zoom: 1;
        letter-spacing: normal;
        word-spacing: normal;
        //vertical-align: top;
        text-rendering: auto
    }
    .hx-u-md-1-24 {
        width: 4.1667%
    }
    .hx-u-md-1-12,
    .hx-u-md-2-24 {
        width: 8.3333%
    }
    .hx-u-md-1-8,
    .hx-u-md-3-24 {
        width: 12.5%
    }
    .hx-u-md-1-6,
    .hx-u-md-4-24 {
        width: 16.6667%
    }
    .hx-u-md-1-5 {
        width: 20%
    }
    .hx-u-md-5-24 {
        width: 20.8333%
    }
    .hx-u-md-1-4,
    .hx-u-md-6-24 {
        width: 25%
    }
    .hx-u-md-7-24 {
        width: 29.1667%
    }
    .hx-u-md-1-3,
    .hx-u-md-8-24 {
        width: 33.3333%
    }
    .hx-u-md-3-8,
    .hx-u-md-9-24 {
        width: 37.5%
    }
    .hx-u-md-2-5 {
        width: 40%
    }
    .hx-u-md-10-24,
    .hx-u-md-5-12 {
        width: 41.6667%
    }
    .hx-u-md-11-24 {
        width: 45.8333%
    }
    .hx-u-md-1-2,
    .hx-u-md-12-24 {
        width: 50%
    }
    .hx-u-md-13-24 {
        width: 54.1667%
    }
    .hx-u-md-14-24,
    .hx-u-md-7-12 {
        width: 58.3333%
    }
    .hx-u-md-3-5 {
        width: 60%
    }
    .hx-u-md-15-24,
    .hx-u-md-5-8 {
        width: 62.5%
    }
    .hx-u-md-16-24,
    .hx-u-md-2-3 {
        width: 66.6667%
    }
    .hx-u-md-17-24 {
        width: 70.8333%
    }
    .hx-u-md-18-24,
    .hx-u-md-3-4 {
        width: 75%
    }
    .hx-u-md-19-24 {
        width: 79.1667%
    }
    .hx-u-md-4-5 {
        width: 80%
    }
    .hx-u-md-20-24,
    .hx-u-md-5-6 {
        width: 83.3333%
    }
    .hx-u-md-21-24,
    .hx-u-md-7-8 {
        width: 87.5%
    }
    .hx-u-md-11-12,
    .hx-u-md-22-24 {
        width: 91.6667%
    }
    .hx-u-md-23-24 {
        width: 95.8333%
    }
    .hx-u-md-1,
    .hx-u-md-1-1,
    .hx-u-md-24-24,
    .hx-u-md-5-5 {
        width: 100%
    }
}

@media screen and (min-width:64em) {
    .hx-u-lg-1,
    .hx-u-lg-1-1,
    .hx-u-lg-1-12,
    .hx-u-lg-1-2,
    .hx-u-lg-1-24,
    .hx-u-lg-1-3,
    .hx-u-lg-1-4,
    .hx-u-lg-1-5,
    .hx-u-lg-1-6,
    .hx-u-lg-1-8,
    .hx-u-lg-10-24,
    .hx-u-lg-11-12,
    .hx-u-lg-11-24,
    .hx-u-lg-12-24,
    .hx-u-lg-13-24,
    .hx-u-lg-14-24,
    .hx-u-lg-15-24,
    .hx-u-lg-16-24,
    .hx-u-lg-17-24,
    .hx-u-lg-18-24,
    .hx-u-lg-19-24,
    .hx-u-lg-2-24,
    .hx-u-lg-2-3,
    .hx-u-lg-2-5,
    .hx-u-lg-20-24,
    .hx-u-lg-21-24,
    .hx-u-lg-22-24,
    .hx-u-lg-23-24,
    .hx-u-lg-24-24,
    .hx-u-lg-3-24,
    .hx-u-lg-3-4,
    .hx-u-lg-3-5,
    .hx-u-lg-3-8,
    .hx-u-lg-4-24,
    .hx-u-lg-4-5,
    .hx-u-lg-5-12,
    .hx-u-lg-5-24,
    .hx-u-lg-5-5,
    .hx-u-lg-5-6,
    .hx-u-lg-5-8,
    .hx-u-lg-6-24,
    .hx-u-lg-7-12,
    .hx-u-lg-7-24,
    .hx-u-lg-7-8,
    .hx-u-lg-8-24,
    .hx-u-lg-9-24 {
        display: inline-block;
        zoom: 1;
        letter-spacing: normal;
        word-spacing: normal;
        //vertical-align: top;
        text-rendering: auto
    }
    .hx-u-lg-1-24 {
        width: 4.1667%
    }
    .hx-u-lg-1-12,
    .hx-u-lg-2-24 {
        width: 8.3333%
    }
    .hx-u-lg-1-8,
    .hx-u-lg-3-24 {
        width: 12.5%
    }
    .hx-u-lg-1-6,
    .hx-u-lg-4-24 {
        width: 16.6667%
    }
    .hx-u-lg-1-5 {
        width: 20%
    }
    .hx-u-lg-5-24 {
        width: 20.8333%
    }
    .hx-u-lg-1-4,
    .hx-u-lg-6-24 {
        width: 25%
    }
    .hx-u-lg-7-24 {
        width: 29.1667%
    }
    .hx-u-lg-1-3,
    .hx-u-lg-8-24 {
        width: 33.3333%
    }
    .hx-u-lg-3-8,
    .hx-u-lg-9-24 {
        width: 37.5%
    }
    .hx-u-lg-2-5 {
        width: 40%
    }
    .hx-u-lg-10-24,
    .hx-u-lg-5-12 {
        width: 41.6667%
    }
    .hx-u-lg-11-24 {
        width: 45.8333%
    }
    .hx-u-lg-1-2,
    .hx-u-lg-12-24 {
        width: 50%
    }
    .hx-u-lg-13-24 {
        width: 54.1667%
    }
    .hx-u-lg-14-24,
    .hx-u-lg-7-12 {
        width: 58.3333%
    }
    .hx-u-lg-3-5 {
        width: 60%
    }
    .hx-u-lg-15-24,
    .hx-u-lg-5-8 {
        width: 62.5%
    }
    .hx-u-lg-16-24,
    .hx-u-lg-2-3 {
        width: 66.6667%
    }
    .hx-u-lg-17-24 {
        width: 70.8333%
    }
    .hx-u-lg-18-24,
    .hx-u-lg-3-4 {
        width: 75%
    }
    .hx-u-lg-19-24 {
        width: 79.1667%
    }
    .hx-u-lg-4-5 {
        width: 80%
    }
    .hx-u-lg-20-24,
    .hx-u-lg-5-6 {
        width: 83.3333%
    }
    .hx-u-lg-21-24,
    .hx-u-lg-7-8 {
        width: 87.5%
    }
    .hx-u-lg-11-12,
    .hx-u-lg-22-24 {
        width: 91.6667%
    }
    .hx-u-lg-23-24 {
        width: 95.8333%
    }
    .hx-u-lg-1,
    .hx-u-lg-1-1,
    .hx-u-lg-24-24,
    .hx-u-lg-5-5 {
        width: 100%
    }
}

@media screen and (min-width:80em) {
    .hx-u-xl-1,
    .hx-u-xl-1-1,
    .hx-u-xl-1-12,
    .hx-u-xl-1-2,
    .hx-u-xl-1-24,
    .hx-u-xl-1-3,
    .hx-u-xl-1-4,
    .hx-u-xl-1-5,
    .hx-u-xl-1-6,
    .hx-u-xl-1-8,
    .hx-u-xl-10-24,
    .hx-u-xl-11-12,
    .hx-u-xl-11-24,
    .hx-u-xl-12-24,
    .hx-u-xl-13-24,
    .hx-u-xl-14-24,
    .hx-u-xl-15-24,
    .hx-u-xl-16-24,
    .hx-u-xl-17-24,
    .hx-u-xl-18-24,
    .hx-u-xl-19-24,
    .hx-u-xl-2-24,
    .hx-u-xl-2-3,
    .hx-u-xl-2-5,
    .hx-u-xl-20-24,
    .hx-u-xl-21-24,
    .hx-u-xl-22-24,
    .hx-u-xl-23-24,
    .hx-u-xl-24-24,
    .hx-u-xl-3-24,
    .hx-u-xl-3-4,
    .hx-u-xl-3-5,
    .hx-u-xl-3-8,
    .hx-u-xl-4-24,
    .hx-u-xl-4-5,
    .hx-u-xl-5-12,
    .hx-u-xl-5-24,
    .hx-u-xl-5-5,
    .hx-u-xl-5-6,
    .hx-u-xl-5-8,
    .hx-u-xl-6-24,
    .hx-u-xl-7-12,
    .hx-u-xl-7-24,
    .hx-u-xl-7-8,
    .hx-u-xl-8-24,
    .hx-u-xl-9-24 {
        display: inline-block;
        zoom: 1;
        letter-spacing: normal;
        word-spacing: normal;
        //vertical-align: top;
        text-rendering: auto
    }
    .hx-u-xl-1-24 {
        width: 4.1667%
    }
    .hx-u-xl-1-12,
    .hx-u-xl-2-24 {
        width: 8.3333%
    }
    .hx-u-xl-1-8,
    .hx-u-xl-3-24 {
        width: 12.5%
    }
    .hx-u-xl-1-6,
    .hx-u-xl-4-24 {
        width: 16.6667%
    }
    .hx-u-xl-1-5 {
        width: 20%
    }
    .hx-u-xl-5-24 {
        width: 20.8333%
    }
    .hx-u-xl-1-4,
    .hx-u-xl-6-24 {
        width: 25%
    }
    .hx-u-xl-7-24 {
        width: 29.1667%
    }
    .hx-u-xl-1-3,
    .hx-u-xl-8-24 {
        width: 33.3333%
    }
    .hx-u-xl-3-8,
    .hx-u-xl-9-24 {
        width: 37.5%
    }
    .hx-u-xl-2-5 {
        width: 40%
    }
    .hx-u-xl-10-24,
    .hx-u-xl-5-12 {
        width: 41.6667%
    }
    .hx-u-xl-11-24 {
        width: 45.8333%
    }
    .hx-u-xl-1-2,
    .hx-u-xl-12-24 {
        width: 50%
    }
    .hx-u-xl-13-24 {
        width: 54.1667%
    }
    .hx-u-xl-14-24,
    .hx-u-xl-7-12 {
        width: 58.3333%
    }
    .hx-u-xl-3-5 {
        width: 60%
    }
    .hx-u-xl-15-24,
    .hx-u-xl-5-8 {
        width: 62.5%
    }
    .hx-u-xl-16-24,
    .hx-u-xl-2-3 {
        width: 66.6667%
    }
    .hx-u-xl-17-24 {
        width: 70.8333%
    }
    .hx-u-xl-18-24,
    .hx-u-xl-3-4 {
        width: 75%
    }
    .hx-u-xl-19-24 {
        width: 79.1667%
    }
    .hx-u-xl-4-5 {
        width: 80%
    }
    .hx-u-xl-20-24,
    .hx-u-xl-5-6 {
        width: 83.3333%
    }
    .hx-u-xl-21-24,
    .hx-u-xl-7-8 {
        width: 87.5%
    }
    .hx-u-xl-11-12,
    .hx-u-xl-22-24 {
        width: 91.6667%
    }
    .hx-u-xl-23-24 {
        width: 95.8333%
    }
    .hx-u-xl-1,
    .hx-u-xl-1-1,
    .hx-u-xl-24-24,
    .hx-u-xl-5-5 {
        width: 100%
    }
}