
//
// Variables
//
// --------------------------------------------------

//== Descriptive colors
// Based on: http://davidwalsh.name/sass-color-variables-dont-suck

//38435d

$white:                         #FFF;
$black:                         #000;
$bali-hai:                      #849AAE;
$biscay:                        #183F66;
$blumine:                       #1D5082;
$cadet-blue:                    #a6b7c1;
$denim:                         #0F84C7;
$elephant:                      #122F4C;
$east-bay:                      #3E607F;
$heather:                       #bac9d3;
$hippie-blue:                   #518CAB;
$geyser:                        #c9d2db;
$gothic:                        #7690A4;
$matisse:                       #205494;
$oxford-blue:                   #38435d;
$st-tropaz:                     #296296;

$gray-base:                     #000;
$gray-darker:                   lighten($gray-base, 13.5%); // #222
$gray-dark:                     lighten($gray-base, 20%);   // #333
$gray:                          lighten($gray-base, 33.5%); // #555
$gray-light:                    lighten($gray-base, 46.7%); // #777
$gray-lighter:                  lighten($gray-base, 93.5%); // #eee
$silver-chalice:                #A0A0A0;


//== Zones
// Histograms
$color-zone-very-light:         rgb(42, 127, 193); //#0086cd;
$color-zone-light:              rgb(30,162,77); //#38c018;
$color-zone-moderate:           rgb(249,170,65); //#a3a118;
$color-zone-hard:               rgb(236,82,48); //#f75404;
$color-zone-maximum:            rgb(180,31,50); //#c01818;

// Kpis
$color-zone-superior:           #0F84C7;
$color-zone-superior-hover:     #0E70A8;
$color-zone-excellent:          #009649;
$color-zone-excellent-hover:    #007B35;
$color-zone-good:               #F9B233;
$color-zone-good-hover:         #DFA02E;
$color-zone-average:            #E84E1B;
$color-zone-average-hover:      #CB4519;
$color-zone-poor:               #BE1621;
$color-zone-poor-hover:         #A3131D;
$color-zone-undefined:          #CFCFCF;

//== Metric colors
//
// Min, max, avg
$color-metric-min:              #ED9F30;
$color-metric-max:              #C01818;
$color-metric-avg:              #308911;

//== Timeline Metrics
$color-metric-energy:           #F75404;
$color-metric-energy-hover:     #DB4B04;
$color-metric-distance:         #FAC51C;
$color-metric-distance-hover:   #DEAF19;
$color-metric-sleep:            #58738F;
$color-metric-sleep-hover:      #58738F;
$color-metric-steps:            #00A885;
$color-metric-steps-hover:      #008C6F;

//== Datatypes
$color-datatype-activity:                 #F9B233;
$color-datatype-activity-hover:           #DFA02E;

$color-datatype-blood-pressure:           #D14841;
$color-datatype-blood-pressure-hover:     #B53F39;

$color-datatype-breathing-rate:           #9365B8;
$color-datatype-breathing-rate-hover:     #7D569C;

$color-datatype-cadence:                  #61BD6D;
$color-datatype-cadence-hover:            #53A15D;

$color-datatype-heart-rate:               #BE1621;
$color-datatype-heart-rate-hover:         #A3131D;

$color-datatype-minute-ventilation:       #553982;
$color-datatype-minute-ventilation-hover: #432D67;

$color-datatype-sleep-position:           #553982;

$color-datatype-spo2:                     #475577;
$color-datatype-spo2-hover:               #38435D;

$font-size-base:               14px;
$font-size-large:              ceil(($font-size-base * 1.25)); // ~18px
$font-size-small:              ceil(($font-size-base * 0.85)); // ~12px
$font-size-extra-small:        floor(($font-size-base * 0.75)); // ~10px

// Font Weights
$font-weight-light:            300;
$font-weight-normal:           400;
$font-weight-medium:           500;
$font-weight-semibold:         600;
$font-weight-bold:             700;
$font-weight-extra-bold:       800;
$font-weight-ultra-bold:       900;

@mixin letter-spacing($letterspacing) {
  letter-spacing: $letterspacing/1000 + em;
}

$font-family-open-sans:        'Open Sans', sans-serif;
$font-family-helvetica-neue:   'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-roboto:           $font-family-open-sans;
$font-family-base:             $font-family-open-sans;
$google-fonts-url:             'https://fonts.googleapis.com/css?family=Roboto:100|Open+Sans:400,300,600,700,800';

//## Dropdown menu container and contents.
$dropdown-font-size:                 ceil(($font-size-base * 0.9)); // 13px



// Utils
.hx-pull-right {
    float: right !important;
}
.margin-top-5 {
  margin-top: 5px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.edit_mode {
  border: 1px solid #F75404;
}



.big_title {
  padding: 20px;
  color: white;
  font-size: 140%;
}

////////// Status


.hx-status-text {
    font-weight: bold;
}
