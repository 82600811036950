@import "./color.scss";
.hx-button {
    /* Structure */
    display: inline-block;
    zoom: 1;
    line-height: normal;
    white-space: nowrap;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-sizing: border-box;
    margin: 2px;
}

.hx-button-normal {
    font-size: 14px;
}

.hx-button-blue {
    background-color: $st-tropaz;
}

.hx-button-inactive {
    opacity: 0.5;
    cursor: default;
}

.hx-button-light-blue  {
    background-color: $cadet-blue;
}

.hx-button-blue:hover {
    background-color: $matisse;
}

.hx-button-green {
    background-color: #122F4C;
}

.hx-button-red {
    background-color: 'red';
}

.hx-button-green:hover {
    background-color: #0088CC;
}

.hx-button-neutral {
    background-color: rgb(180, 180, 180);
}

.hx-button-neutral:hover {
    background-color: rgba(233, 233, 234, 0.8);
}


/* Firefox: Get rid of the inner focus border */

.hx-button::-moz-focus-inner {
    padding: 0;
    border: 0;
}


/* Inherit .hx-g styles */

.hx-button-group {
    letter-spacing: -0.31em;
    /* Webkit: collapse white-space between units */
    *letter-spacing: normal;
    /* reset IE < 8 */
    *word-spacing: -0.43em;
    /* IE < 8: collapse white-space between units */
    text-rendering: optimizespeed;
    /* Webkit: fixes text-rendering: optimizeLegibility */
}

.opera-only :-o-prefocus,
.hx-button-group {
    word-spacing: -0.43em;
}

.hx-button-group .hx-button {
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    text-rendering: auto;
}


/*csslint outline-none:false*/

.hx-button {
    font-family: inherit;
    //font-size: 100%;
    padding: 0.2em 0.5em;
    color: white;
    /* rgba not supported (IE 8) */
    color: rgba(255, 255, 255, 1);
    /* rgba supported */
    border: 1px solid #999;
    /*IE 6/7/8*/
    border: none rgba(0, 0, 0, 0);
    /*IE9 + everything else*/
    text-decoration: none;
}

.hx-button-hover,
.hx-button:hover,
.hx-button:focus {
    /* csslint ignore:start */
    filter: alpha(opacity=90);
    opacity: 0.8;
    /* csslint ignore:end */
    /*background-image: -webkit-linear-gradient(transparent, rgba(0,0,0, 0.05) 40%, rgba(0,0,0, 0.10));
    background-image: linear-gradient(transparent, rgba(0,0,0, 0.05) 40%, rgba(0,0,0, 0.10));*/
}

.hx-button:focus {
    outline: 0;
}

.hx-button-active,
.hx-button:active {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) inset, 0 0 6px rgba(0, 0, 0, 0.20) inset;
    border-color: #000\9;
}

.hx-button[disabled],
.hx-button-disabled,
.hx-button-disabled:hover,
.hx-button-disabled:focus,
.hx-button-disabled:active {
    border: none;
    background-image: none;
    /* csslint ignore:start */
    filter: alpha(opacity=40);
    /* csslint ignore:end */
    opacity: 0.40;
    cursor: not-allowed;
    box-shadow: none;
    pointer-events: none;
}

.hx-button-hidden {
    display: none;
}

.hx-button-primary,
.hx-button-selected,
a.hx-button-primary,
a.hx-button-selected {
    background-color: #0088CC;
    color: #fff;
}


/* Button Groups */

.hx-button-group .hx-button {
    margin: 0;
    border-radius: 0;
    border-right: 1px solid #111;
    /* fallback color for rgba() for IE7/8 */
    border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.hx-button-group .hx-button:first-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}

.hx-button-group .hx-button:last-child {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-right: none;
}

.hx-button-success,
.hx-button-error,
.hx-button-warning,
.hx-button-secondary {
    color: white;
    text-transform: uppercase;
}