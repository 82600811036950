

.alert {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  margin-top: 15px;
  margin-bottom: 5px;
  background-color: $state-danger-bg;
  border: solid 1px $state-danger-border;
  color: white;
  
  .text {
    color: white;
    opacity: 1;

  }
}


.alert-dismissible .close {
  top: 0;
}


.info {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  margin-top: 15px;
  margin-bottom: 5px;
  background-color: $state-info-bg;
  border: solid 1px $state-info-border;
  color: white;
  
  .text {
    color: white;
    opacity: 1;

  }
}


.info-dismissible .close {
  top: 0;
}