@import "./color.scss";

/********************************/
/* No border outside the tables */
/********************************/
table {
  border-collapse: collapse;
}
table td, table th {
  //border: 1px solid black;
}
table tr:first-child th {
  border-top: 0;
}
table tr:last-child td {
  border-bottom: 0;
}
table tr td:first-child,
table tr th:first-child {
  border-left: 0;
}
table tr td:last-child,
table tr th:last-child {
  border-right: 0;
}

.hx-table {
    margin-top: 10px;
    /* Remove spacing between table cells (from Normalize.css) */
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show;
    //border: 1px solid rgba(255,255,255,0.2);
    width: 100%;
    //margin: 0 auto; 
    font-size: 80%;    
}


.hx-table-ranges, .hx-table-records {
    margin-top: 0px;
    width: 100%;
    background-color: rgba(255,255,255,0.7);
}

.hx-table-ranges tbody tr:hover, .hx-table-records tbody tr:hover {
    background-color: rgba(0, 136, 206, 0.3);
    color: #fff;
    cursor: pointer;
}

.hx-table-with-border {
    border: 1px solid black;
}

.hx-table tbody tr:hover {
    background-color: white;
    color: #fff;
}

.hx-table caption {

    font: italic 85%/1 arial, sans-serif;
    padding: 1em 0;
    text-align: center;
}

.hx-table tbody td, thead td,
.hx-table tbody th, thead th {
    border-left: 1px solid rgba(255,255,255,0.2);/*  inner column border */
    color: $elephant;
    border-width: 0 0 0 0px;
    font-size: inherit;
    margin: 0;
    overflow: visible; /*to make ths where the title is really long work*/
    padding: 0.5em 1em; /* cell padding */
}

.hx-table tfoot th {
    //border-left: 1px solid rgba(255,255,255,0.2);/*  inner column border */
    color: $elephant;
    border-width: 0 0 0 0px;
    font-size: inherit;
    margin: 0;
    overflow: visible; /*to make ths where the title is really long work*/
    padding: 0.5em 1em; /* cell padding */
}

.hx-table thead {
    font-weight: bold;
    color: #929494;
    text-align: left;
    vertical-align: bottom;
}

/*
striping:
   even - #fff (white)
   odd  - #f2f2f2 (light gray)
*/
.hx-table a {
    text-decoration: none;
    color: inherit;
}

.hx-table td {
    background-color: transparent;
}

.hx-icon-caret-down {
  content: "\e61f";
}
