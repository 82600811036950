.hx-g {
   // letter-spacing: -.31em;
    text-rendering: optimizespeed;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start
}
@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    table .hx-g {
        display: block
    }
}
.opera-only:-o-prefocus,
.hx-g {
    word-spacing: -.43em
}
.hx-u,
.hx-u-1,
.hx-u-1-1,
.hx-u-1-12,
.hx-u-1-2,
.hx-u-1-24,
.hx-u-1-3,
.hx-u-1-4,
.hx-u-1-5,
.hx-u-1-6,
.hx-u-1-8,
.hx-u-10-24,
.hx-u-11-12,
.hx-u-11-24,
.hx-u-12-24,
.hx-u-13-24,
.hx-u-14-24,
.hx-u-15-24,
.hx-u-16-24,
.hx-u-17-24,
.hx-u-18-24,
.hx-u-19-24,
.hx-u-2-24,
.hx-u-2-3,
.hx-u-2-5,
.hx-u-20-24,
.hx-u-21-24,
.hx-u-22-24,
.hx-u-23-24,
.hx-u-24-24,
.hx-u-3-24,
.hx-u-3-4,
.hx-u-3-5,
.hx-u-3-8,
.hx-u-4-24,
.hx-u-4-5,
.hx-u-5-12,
.hx-u-5-24,
.hx-u-5-5,
.hx-u-5-6,
.hx-u-5-8,
.hx-u-6-24,
.hx-u-7-12,
.hx-u-7-24,
.hx-u-7-8,
.hx-u-8-24,
.hx-u-9-24 {
    display: inline-block;
    zoom: 1;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    text-rendering: auto
}

.hx-u-1-24 {
    width: 4.1667%
}
.hx-u-1-12,
.hx-u-2-24 {
    width: 8.3333%
}
.hx-u-1-8,
.hx-u-3-24 {
    width: 12.5%
}
.hx-u-1-6,
.hx-u-4-24 {
    width: 16.6667%
}
.hx-u-1-5 {
    width: 20%
}
.hx-u-5-24 {
    width: 20.8333%
}
.hx-u-1-4,
.hx-u-6-24 {
    width: 25%
}
.hx-u-7-24 {
    width: 29.1667%
}
.hx-u-1-3,
.hx-u-8-24 {
    width: 33.3333%
}
.hx-u-3-8,
.hx-u-9-24 {
    width: 37.5%
}
.hx-u-2-5 {
    width: 40%
}
.hx-u-10-24,
.hx-u-5-12 {
    width: 41.6667%
}
.hx-u-11-24 {
    width: 45.8333%
}
.hx-u-1-2,
.hx-u-12-24 {
    width: 50%
}
.hx-u-13-24 {
    width: 54.1667%
}
.hx-u-14-24,
.hx-u-7-12 {
    width: 58.3333%
}
.hx-u-3-5 {
    width: 60%
}
.hx-u-15-24,
.hx-u-5-8 {
    width: 62.5%
}
.hx-u-16-24,
.hx-u-2-3 {
    width: 66.6667%
}
.hx-u-17-24 {
    width: 70.8333%
}
.hx-u-18-24,
.hx-u-3-4 {
    width: 75%
}
.hx-u-19-24 {
    width: 79.1667%
}
.hx-u-4-5 {
    width: 80%
}
.hx-u-20-24,
.hx-u-5-6 {
    width: 83.3333%
}
.hx-u-21-24,
.hx-u-7-8 {
    width: 87.5%
}
.hx-u-11-12,
.hx-u-22-24 {
    width: 91.6667%
}
.hx-u-23-24 {
    width: 95.8333%
}
.hx-u-1,
.hx-u-1-1,
.hx-u-24-24,
.hx-u-5-5 {
    width: 100%
}