@import 'color';

$font-family-open-sans:        'Open Sans', sans-serif;
$font-family-helvetica-neue:   'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-roboto:           'Roboto', sans-serif;
$font-family-base:             $font-family-open-sans;
$google-fonts-url:             'https://fonts.googleapis.com/css?family=Roboto:300|Open+Sans:400,300,600,700,800';

@import url($google-fonts-url);

//== Nav boxes
$nav-boxes-active-link-border-color:        $hippie-blue;

// Font Weights
$font-weight-light:            300;
$font-weight-normal:           400;
$font-weight-medium:           500;
$font-weight-semibold:         600;
$font-weight-bold:             700;
$font-weight-extra-bold:       800;
$font-weight-ultra-bold:       900;

// Font, line-height, and color for body text, headings, and more.


$font-size-base:               14px;
$font-size-large:              ceil(($font-size-base * 1.25)); // ~18px
$font-size-small:              ceil(($font-size-base * 0.85)); // ~12px
$font-size-extra-small:        floor(($font-size-base * 0.75)); // ~10px

// Based on: http://justinmarsan.com/css-letter-spacing-in-photoshop-and-browsers/
@mixin letter-spacing($letterspacing) {
  letter-spacing: $letterspacing/1000 + em;
}


$screen-xs-max: 300;
$screen-sm-min: 100;
$screen-md-min: 100;
$screen-lg-min: 100;

@mixin opacity($opacity) {
    opacity: $opacity;
}


//== Dropdowns
//
//## Dropdown menu container and contents.
$dropdown-font-size:                 ceil(($font-size-base * 0.9)); // 13px

// Dropdowns in nav bar
$dropdown-nav-bg:                    $oxford-blue;
$dropdown-nav-border:                darken($dropdown-nav-bg, 5%);
$dropdown-nav-link-color:            $white;
$dropdown-nav-link-hover-color:      darken($dropdown-nav-link-color, 5%);
$dropdown-nav-link-hover-bg:         lighten($dropdown-nav-bg, 5%);
$dropdown-nav-header-color:          $white;
$dropdown-nav-divider-bg:            darken($dropdown-nav-bg, 5%);

.missing-info {
   padding: 15px;
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
opacity: 0.5;
}