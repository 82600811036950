@import 'variable';
@import '../app';
.bfooter {
    position: fixed;
    bottom: 0;
    width: 1024px;
}

.footer {
    margin-top: 15px;
    padding: 15px;
    background-color: $elephant;
    color: white;
}

section {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
    font-family: $font-family-open-sans;
    margin-top: 15px;
    .padding-bottom {
        padding-bottom: 10px;
    }
    .header {
        padding: 5px;
        margin-bottom: 2px; // font
        font-size: 110%;
        font-weight: 100;
        color: $elephant;
    }
    .subheader {
        padding: 5px;
        margin-bottom: 2px; // font
        font-size: 110%;
        font-weight: 100;
        color: $elephant;
    }
    .header a,
    .title a {
        text-decoration: none;
        color: $elephant;
    }
    .body {
        background-color: rgba(255, 255, 255, 0.8);
        margin-bottom: 2px;
    }
    .form {
        //background-color: rgba(255,255,255,1);    
    }
    .title-wrapper {
        // font
        color: $elephant;
        background-color: rgba(255, 255, 255, 1);
        margin-bottom: 1px;
        padding-left: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .title {
        font-size: 100%;
        font-weight: 100;
        padding: 5px 5px 5px 5px; // font
        font-weight: 100;
        color: $elephant;
        font-weight: bold;
        background-color: white;
    }
    .name {
        font-weight: 100;
        padding: 0px 5px 1px 5px; // font
        font-size: 80%;
        font-weight: 100;
        color: $elephant;
        font-weight: bold;
        background-color: white;
    }
    .shelp {
        height: 30px;
        width: 30px;
        border-radius: 30px;
        background-color: orangered;
        color: white;
        font-size: 70%;
        font-style: italic;
        text-align: center;
        vertical-align: middle;
        line-height: 30px;
        cursor: pointer;
    }
    .occurence {
        height: 30px;
        width: 30px;
        border-radius: 30px;
        background-color: #0F84C7;
        color: white;
        font-size: 60%;
        text-align: center;
        vertical-align: middle;
        line-height: 30px;
    }
}

.title-link-inactive {
    opacity: 0.5;
}

.title-link {
    cursor: pointer;
}